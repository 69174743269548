.slogna-text {
  margin-top: 10px;
  text-align: center;
}

.img-1 {
  width: inherit;
  height: fit-content;
}

.margin-top-30 {
  margin-top: 30px;
}

.margin-10 {
  margin: 10px;
}

.margin-top-10 {
  margin-top: 10px;
}

.margin-top-20 {
  margin-top: 20px;
}

.margin-right-10 {
  margin-right: 10px;
}

.about-us {
  background: #3a8de6;
  height: 60%;
  width: 100%;
  text-align: center;
  color: white;
  border: solid 1px grey;
  font-family: "Roboto", Arial, Tahoma, sans-serif;
  font-weight: 300;
  font-size: 28px;
  line-height: 30px;
  padding-top: 86px;
}

.about-us > h3 {
  font-family: "Roboto", Arial, Tahoma, sans-serif !important;
  font-weight: 300;
  font-size: 20px;
  line-height: 30px;
  margin: 5% 7%;
}

.margin-10 {
  margin: 10px;
}

.margin-20 {
  margin: 20px;
}

.margin-60 {
  margin: 60px;
}

.padding-4-rem {
  padding: 4rem;
}

.padding-20-px {
  padding: 20px;
}

.service-border {
  border: 0.2rem solid #ececec;
  border-radius: 8px;
}
.grey-left-border {
  border-left: 1px solid #ececec;
}
.location-ico {
  align-self: flex-end;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  transform-origin: bottom;
}
.bounce-1 {
  animation-name: bounce-1;
  animation-timing-function: linear;
}
@keyframes bounce-1 {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-50px);
  }
  100% {
    transform: translateY(0);
  }
}

.bounce-2 {
  animation-name: bounce-2;
  animation-timing-function: linear;
}
@keyframes bounce-2 {
  0% {
    transform: translateY(-50px);
  }
  50% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(0);
  }
}

.chat-bot-container {
  float: right;
  position: relative;
  top: 138rem;
  left: 8rem;
  z-index: 1;
}

.chat-bot-ico {
  position: relative;
  bottom: 55px;
  left: 76rem;
  top: -7px;
}
